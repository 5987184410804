<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('Создать паллет №5') }}</p>
                <div>
                    <crm-store-update-close
                    :permission="$options.name"
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <el-row > 
            <el-col :span="16">
                <div class="gc-card__body px-3 py-4">
                    <div class="app-form__group mb-0">
                        <div
                            class="
                                gc-card
                                rounded-sm
                                mb-4
                                p-relative
                                mr-3
                                px-3 py-3
                            "
                        >
                            <div class="">
                                <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered layout__initial">
                                    <thead>
                                        <tr>
                                            <th class="w50p" >
                                                №
                                            </th>
                                            <th>
                                                Название
                                            </th>

                                            <th>
                                                Количество
                                            </th>

                                            <th>
                                                Вес
                                            </th>
                                        </tr>
                                    </thead>

                                    <transition-group name="flip-list" tag="tbody">
                                        <tr v-for="pal in pallets" :key="pal.id" class="cursor-pointer">
                                            <td>
                                                {{ pal.id }}
                                            </td>
                                            <td>
                                                {{ pal.name }}
                                            </td>
                                            <td>
                                                {{ pal.quantitiy }}
                                            </td>
                                            <td>
                                                {{ pal.weight }}
                                            </td>
                                        </tr>
                                    </transition-group>
                                </table>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col class="height__full" :span="8">
                <div class="px-3 py-4">
                    <div
                        class="
                            gc-card
                            rounded-sm
                            mb-4
                            p-relative
                            mr-3
                        "
                    >
                        <div class="gc-card__body px-3 py-2">
                            <div class="text-descr">
                                <span
                                    class="
                                    tracking__bold
                                    font20
                                    "
                                    >{{
                                        $t("Инфо о паллете")
                                    }}</span
                                >
                                <div class="d-block align-center font-medium">
                                    <div class="tracking__flex">
                                        <span class="tracking__light mt-5 ">ФИО клиента</span>
                                        <span class="tracking__bold mt-2">Иван Васильевич</span>
                                        <span class="tracking__light mt-3">Телефон:</span>
                                        <span class="tracking__bold mt-2">+99899 123 12 13</span>
                                        <span class="tracking__light mt-3">Email:</span>
                                        <span class="tracking__bold mt-2">ivantest@mail.ru</span>
                                        <span class="tracking__light mt-3">Адрес:</span>
                                        <span class="tracking__bold mt-2 mb-2">г. Ташкент, Чиланзарский район, квартал 5, дом 42</span>
                                        <span class="mt-3"><span class="tracking__light">Груз:</span> <span class="tracking__bold">6 шт</span></span>
                                        <span class="tracking__light mt-3 ">Доставка:</span>
                                        <div class="mt-2">
                                            <select-cargo
                                                class="w100"
                                                :size="'medium'"
                                                :placeholder="'Выбрать груз'"
                                                :id="value1"
                                                :board_id="value1"
                                                v-model="value1"
                                                >
                                            </select-cargo>
                                        </div>
                                        <div class="mt-3">
                                            <span ><span class="tracking__light">Цена:</span> <span class="tracking__bold">200$ (2 200 000)</span></span>
                                        </div>
                                        <div class="mt-3 cargo__right">
                                            <el-button size="medium" type="warning" plain>Закрыть заявку</el-button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
</div>
</template>

<script>
import selectBoard from "@/components/inventory/select-deal-board";
import SelectCargo from "@/components/inventory/select-cargo";
export default {
data: () => ({
    formLabelAlign: {
        name: '',
        region: '',
        type: ''
    },
    mode: true,
    value1: '',
    value2: '',
    filterForm: {
            id: "",
            customer: "",
            paymentType:"",
            typePayment: "",
        
            time: "",
            sum: "",
            currency: "",
            created_at: "",
            updated_at: "",
    },
    columns: {
            id: {
                show: true,
                title: "№",
                sortable: true,
                column: "id",
            },

            customer: {
                show: true,
                title: "Типы расхода",
                sortable: true,
                column: "customer",
            },

            paymentType: {
                show: true,
                title: "Тип платежа",
                sortable: true,
                column: "paymentType",
            },

            typePayment: {
                show: true,
                title: "Тип оплаты",
                sortable: true,
                column: "typePayment",
            },

            

            time: {
                show: true,
                title: "Время",
                sortable: true,
                column: "time",
            },
            
            sum: {
                show: true,
                title: "Сумма",
                sortable: true,
                column: "sum",
            },


            currency: {
                show: true,
                title: "Валюта ",
                sortable: true,
                column: "currency",
            },

            created_at: {
                show: true,
                title: "Дата создания",
                sortable: true,
                column: "created_at",
            },

            updated_at: {
                show: true,
                title: "Дата изменена ",
                sortable: true,
                column: "updated_at",
            },

            settings: {
                show: true,
                title: "Профиль",
                sortable: false,
                column: "settings",
            },
    },
    tableData: [{
        date: 'Монитор Dell',
        name: '2',
        address: '3.8',
        code: '67804289323'
        }, {
        date: 'Монитор LG',
        name: '3',
        address: '2.8',
        code: '67804289323'
    },],
    pallets: [
            {
                id: 1,
                name: 'Iphone 12 pro max',
                quantitiy: '3',
                submit: '6',
                weight: '8'
            },
            {
                id: 2,
                name: 'Keychron K2',
                quantitiy: '3',
                submit: '12',
                weight: '8'
            },
            {
                id: 3,
                name: 'Sony Headphones',
                quantitiy: '2',
                submit: '6',
                weight: '8'
            },
            {
                id: 4,
                name: 'Playstation 5',
                quantitiy: '8',
                submit: '36',
                weight: '8'
            },
    ],
}),
components: {
    selectBoard, SelectCargo
}
}
</script>

<style>

</style>
